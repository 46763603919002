import React from "react"
import styled from "styled-components"
import NewsList from "../components/NewsList"
import PageHeadBanner from "../components/PageHeadBanner"
import DownloadProspectusBox from "../components/ProspectusDownloadBox"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const NewsContainer = styled.div`
  ul {
    list-style: none;
    li {
      margin-top: 30px;
    }
  }
`

const Noticeboard = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"News, Events and Noticeboard"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Noticeboard"}
        subtitle={"News, Events and Noticeboard"}
      />

      <section className="pt-70">
        <div className="container">
          <div className={"row"}>
            <div className="col-12">
              <DownloadProspectusBox
                link={siteData.prospectus_link}
                heading={"Download Prospectus (2021-2022)"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="terms-conditions-area pt-70 pb-100">
        <div className="container">
          <div className={"row"}>
            <div className="col-12">
              <NewsContainer>
                <NewsList limit={20} />
              </NewsContainer>
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export default Noticeboard
