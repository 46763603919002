import React from "react"
import styled from "styled-components"
const EventListUL = styled.ul`
  li {
    margin: 0;
    padding: 0 !important;

    h4 {
      font-size: 17px;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
`
const NewsList = ({ limit = null }) => {
  return (
    <EventListUL className="events-list">
      <li>
        <span>23-07-2021</span>
        <h4>
          <a href="/">Admission Opens for Arts, Science and Commerce</a>
        </h4>
        <p>
          Accepting applications for admissions to Higher Secondary First Year
          (2021-2022) for Arts, Science and Commerce Stream in both English and
          Assamese Medium
        </p>
        {/* <a href="/" className="read-more">
          View More
        </a> */}
      </li>

      <li>
        <span>23-07-2021</span>
        <h4>
          <a href="/">Prospectus and Application Form for session 2021-2022</a>
        </h4>
        <p>
          Prospectus and Application Form for session 2021-2022 are now
          available to download.
        </p>
        <a href="/" className="read-more">
          Download Link
        </a>
      </li>

      <li>
        <span>23-07-2021</span>
        <h4>
          <a href="/">
            Accepting Online Inquiries for Admission and Scholarships
          </a>
        </h4>
        <p>
          Online Contact form is now availabe for Inquiries related to
          scholarships and Admission into Science, Commerce and Arts Stream.
        </p>
      </li>
    </EventListUL>
  )
}

export default NewsList
